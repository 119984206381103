import React from "react"

const Logo = (props: { style?: React.CSSProperties }) => {
  return (
    <div
      className="logo"
      style={{
        margin: 16,
        float: "left",
        // background: "rgba(255,255,255,0.3)",
        fontSize: "1.5em",
        fontWeight: "bold",
        color: "white",
        ...props.style,
      }}
    >
      Major Exchanges
    </div>
  )
}

export default Logo
