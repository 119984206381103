import { Tag } from "antd"

interface StateTagProps {
  state: number
}

export default function StateTag(props: StateTagProps) {
  /* OUTPUT */
  switch (props.state) {
    case 1:
      return <Tag>En cours</Tag>
    case 2:
      return <Tag color="blue">Rédaction en cours</Tag>
    case 3:
      return <Tag color="green">Réalisée</Tag>
    default:
      return <></>
  }
}
