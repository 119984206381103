import React, { Suspense } from "react"
import { Layout, Menu } from "antd"
import { BookOutlined, CopyOutlined, HomeOutlined, PoweroffOutlined } from "@ant-design/icons"
import { Link, Routes, useMutation } from "blitz"
import logout from "app/auth/mutations/logout"
import Logo from "./Logo"
import ConseillerTag from "./ConseillerTag"
import { useCurrentUser } from "../hooks/useCurrentUser"

const { Header } = Layout
const { SubMenu } = Menu
const HeaderBar = (props: { withLogo?: boolean }) => {
  const user = useCurrentUser()
  const [logoutMutation] = useMutation(logout)
  return (
    <Header
      className="header"
      style={{
        position: "fixed",
        zIndex: 1,
        width: "100%",
        boxShadow: "3px 3px 5px rgba(0,0,0,0.3)",
        backgroundColor: "#005493",
      }}
    >
      {props.withLogo && <Logo style={{ margin: 0, marginRight: 10 }} />}
      <Menu style={{ backgroundColor: "#005493" }} theme="dark" mode="horizontal">
        <ConseillerTag key="cons" />
        <Menu.Item icon={<HomeOutlined />} key="desktop">
          <Link href="/">Bureau</Link>
        </Menu.Item>
        <Menu.Item icon={<CopyOutlined />} key="clients">
          <Link href={Routes.ClientsPage()}>Clients</Link>
        </Menu.Item>
        <Menu.Item icon={<BookOutlined />} key="guides">
          <Link href={Routes.GuidesPage()}>Guides</Link>
        </Menu.Item>
        <Menu.Item
          icon={<PoweroffOutlined />}
          key="logout"
          onClick={() => {
            if (confirm("Voulez-vous vous déconnecter de Major Exchanges ?")) logoutMutation()
          }}
        >
          Déconnexion
        </Menu.Item>
      </Menu>
    </Header>
  )
}

export default function SuspensHeaderBar(props: { withLogo?: boolean }) {
  return (
    <Suspense fallback="Loading...">
      <HeaderBar withLogo={props.withLogo} />
    </Suspense>
  )
}
