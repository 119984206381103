import { Suspense } from "react"
import { useCurrentUser } from "../hooks/useCurrentUser"
import { Menu } from "antd"

function ConseillerTagContent() {
  const user = useCurrentUser()
  /* OUTPUT */
  if (user?.role === "ADMIN") {
    return (
      <Menu.Item disabled>
        <span
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            padding: "10px 20px",
            boxShadow: "inset -1px 1px 3px rgba(0,0,0,0.3)",
          }}
        >
          CONSEILLER
        </span>
      </Menu.Item>
    )
  }
  return <Menu.Item></Menu.Item>
}

export default function ConseillerTag() {
  return (
    <Suspense fallback={<div></div>}>
      <ConseillerTagContent />
    </Suspense>
  )
}
