import { BlitzPage, Routes, useQuery, Link, useMutation } from "blitz"
import MainLayout from "app/core/layouts/MainLayout"
import React, { Suspense, useState } from "react"
import { Button, Card, Input, Typography, Table } from "antd"
import { CheckCircleOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import getStudies from "app/studies/queries/getStudies"
import deleteStudyMutation from "app/studies/mutations/deleteStudy"
import { Candidate, Study, User } from "db"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import StateTag from "app/studies/components/StateTag"

const { Title } = Typography

interface NewStudyPanelProps {
  title: React.ReactNode
  description: React.ReactNode
  name: string
}

const NewStudyPanel = (props: NewStudyPanelProps) => {
  const user = useCurrentUser()
  /* OUTPUT */
  return (
    <Card
      style={{ flex: 1, margin: 10 }}
      hoverable
      actions={[
        <Link href={Routes.NewStudyPage({ type: props.name })} key="select">
          <Button
            disabled={!Boolean(user?.credits && user?.credits > 0)}
            type="primary"
            icon={<CheckCircleOutlined />}
          >
            Nouvelle étude
          </Button>
        </Link>,
      ]}
    >
      <Card.Meta title={props.title} description={props.description} />
    </Card>
  )
}

const StudiesTable = (props: { showUser: boolean }) => {
  /* STATE */
  const [query, setQuery] = useState("")

  /* MUTATION */
  const [deleteStudy] = useMutation(deleteStudyMutation)

  /* VALUES */
  const studiesColumns = [
    ...(props.showUser
      ? [
          {
            title: "Client",
            dataIndex: "client",
            key: "user",
            render: (_txt, record: Study & { user: User | null }) => `${record.user?.email}`,
          },
        ]
      : []),
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Objectif",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Candidat",
      dataIndex: "candidate",
      key: "candidate",
      render: (_txt, record: Study & { candidate: Candidate | null }) =>
        `${record.candidate?.firstname} ${record.candidate?.lastname.toLocaleUpperCase()}`,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: string) =>
        ({ profile: "Simplifiée", management: "Approfondie" }[type] || "N/A"),
    },
    {
      title: "Créée le",
      key: "createdAt",
      render: (_txt, record: Study) => record.createdAt.toLocaleDateString(),
    },
    {
      title: "Statut",
      key: "state",
      render: (_txt, record: Study) => <StateTag state={record.state} />,
    },
    {
      title: "Actions",
      render: (_txt, record: Study) => (
        <>
          <Link href={Routes.ShowStudyPage({ studyId: record.id })}>
            <Button>Accéder au dossier</Button>
          </Link>
          <Button
            danger
            type="dashed"
            onClick={() =>
              confirm("Etes-vous sûr de vouloir supprimer cette étude ?") &&
              deleteStudy({ id: record.id }).then(() => refetch())
            }
            style={{ marginLeft: 10 }}
            icon={<DeleteOutlined />}
          />
        </>
      ),
    },
  ]

  /* DATA */
  const user = useCurrentUser()
  const [{ studies }, { refetch }] = useQuery(
    getStudies,
    query
      ? {
          where: {
            OR: [
              { code: { contains: query, mode: "insensitive" } },
              { name: { contains: query, mode: "insensitive" } },
              { candidate: { lastname: { contains: query, mode: "insensitive" } } },
            ],
          },
        }
      : {}
  )

  /* OUTPUT */
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Input.Search
          allowClear
          placeholder="Rechercher par code, objectif ou nom de candidat"
          style={{ maxWidth: 400, marginBottom: 10 }}
          onSearch={(value) => setQuery(value)}
        />

        <Link href={Routes.NewStudyPage({ type: "management" })} key="select">
          <Button
            disabled={!Boolean(user?.credits && user?.credits > 0)}
            type="primary"
            icon={<PlusCircleOutlined />}
          >
            Commencer une nouvelle étude
          </Button>
        </Link>
      </div>
      <Table
        dataSource={studies}
        columns={user?.type ? studiesColumns.filter((c) => c.title !== "Type") : studiesColumns}
      />
    </div>
  )
}

const NewStudyPanels = () => {
  const user = useCurrentUser()

  return (
    <div style={{ display: "flex" }}>
      <NewStudyPanel
        name="profile"
        title="Simplifiée"
        description={
          <div>
            <p style={{ fontVariant: "italic", fontWeight: "bold", marginBottom: "0.5em" }}>
              Pour vos études de personnalité
            </p>
            <p style={{ marginBottom: "0.2em" }}>
              Major Exchanges <i>Simplifiée</i> est utilisé depuis de nombreuses années par des
              entreprises et organisations nationales et internationales.
            </p>
            <p style={{ marginBottom: "0.2em" }}>
              Il étudie les capacités d’implication et le dynamisme comportemental d’un candidat ou
              d’un collaborateur sur 48 critères clefs.
            </p>
            <p style={{ marginBottom: "0.2em" }}>
              Il apporte une connaissance plus approfondie de la personnalité.
            </p>
            <p style={{ marginBottom: "0.2em" }}>
              Il fait appel aux réactions primaires - inconscientes - d’une individualité qui
              impactent la qualité de son implication.
            </p>
            <p style={{ marginBottom: "0.5em" }}>
              Major Exchanges <i>Simplifiée</i> réalise des études rapides, fiables, tout en
              laissant une place importante à la convivialité, à la curiosité et à la réflexion de
              par sa présentation.
            </p>
            {user?.credits && user?.credits > 0 ? (
              <p style={{ fontWeight: "bold" }}>
                {user!.credits} étude{user!.credits > 1 ? "s" : ""} disponible
                {user!.credits > 1 ? "s" : ""} (Simplifiée ou Approfondie)
              </p>
            ) : (
              <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                Contactez-nous pour débloquer plus d’études
              </p>
              // <p style={{ fontWeight: "bold" }}>40 € HT / étude</p>
            )}
          </div>
        }
      />
      <NewStudyPanel
        name="management"
        title="Approfondie"
        description={
          <div>
            <p style={{ fontVariant: "italic", fontWeight: "bold", marginBottom: "0.5em" }}>
              Pour vos études de personnalité + Un guide d’entretien + Une personnalisation de vos
              études
            </p>
            <p style={{ marginBottom: "0.2em" }}>
              Major Exchanges <i>Expertise</i> s’appuie sur une méthodologie de profiling développée
              à l’origine pour le commandement militaire.
            </p>
            <p style={{ marginBottom: "0.2em" }}>
              Il fait appel aux réactions primaires - inconscientes - d’une individualité qui
              impactent la qualité de son implication.
            </p>
            <p style={{ marginBottom: "0.2em" }}>
              Bien plus qu’un test : un guide d’entretien pour valoriser votre propre réflexion lors
              de vos études comportementales des candidats et des collaborateurs.
            </p>
            <p style={{ marginBottom: "0.5em" }}>
              Il vous donne aussi la possibilité de personnaliser vos études par un apport conjoint
              au résultat de Major Exchanges : intervention facilitée sur base graphique et écrite.
            </p>
            {user?.credits && user?.credits > 0 ? (
              <p style={{ fontWeight: "bold" }}>
                {user!.credits} étude{user!.credits > 1 ? "s" : ""} disponible
                {user!.credits > 1 ? "s" : ""} (Simplifiée ou Approfondie)
              </p>
            ) : (
              <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                Contactez-nous pour débloquer plus d’études
              </p>
              // <p style={{ fontWeight: "bold" }}>60 € HT / étude</p>
            )}
          </div>
        }
      />
    </div>
  )
}

const Home: BlitzPage = () => {
  const user = useCurrentUser()
  /* OUTPUT */
  return (
    <>
      <div>
        <Title level={2}>Vos études</Title>
        <Suspense fallback="Loading...">
          <StudiesTable showUser={user?.role === "ADMIN"} />
        </Suspense>
      </div>
      <div style={{ opacity: 0.3 }}>Utilisateur : {user?.email}</div>
    </>
  )
}

Home.authenticate = { redirectTo: Routes.LoginPage() }
Home.suppressFirstRenderFlicker = true
Home.getLayout = (page) => (
  <MainLayout title="Home" breadcrumb={["Bureau"]}>
    <Suspense fallback="Loading...">{page}</Suspense>
  </MainLayout>
)

export default Home
