import React, { ReactNode } from "react"
import { Head, Link, Routes } from "blitz"
import { Breadcrumb, Layout, Menu } from "antd"

import HeaderBar from "../partials/HeaderBar"
import Logo from "../partials/Logo"
const { Header, Content, Footer } = Layout

type LayoutProps = {
  title?: string
  breadcrumb?: (JSX.Element | string)[]
  guest?: boolean
  children: ReactNode
}

const MainLayout = ({ title, children, breadcrumb, guest }: LayoutProps) => {
  /* OUTPUT */
  return (
    <>
      <Head>
        <title>{title ? `${title} | Major Exchanges` : "Major Exchanges"}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Layout>
        {guest ? (
          <Header style={{ backgroundColor: "#005493" }}>
            <Logo style={{ margin: 0, marginRight: 10 }} />
            <Menu theme="dark" mode="horizontal" style={{ backgroundColor: "#005493" }}>
              <Menu.Item key="login">
                <Link href={Routes.LoginPage()}>Connexion</Link>
              </Menu.Item>
              <Menu.Item key="signup">
                <Link href={Routes.SignupPage()}>Inscription</Link>
              </Menu.Item>
            </Menu>
          </Header>
        ) : (
          <HeaderBar withLogo={true} />
        )}
        <Content style={{ padding: "0 50px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            {breadcrumb && (
              <>
                <Breadcrumb.Item>Major Exchanges</Breadcrumb.Item>
                {breadcrumb.map((b) => (
                  <Breadcrumb.Item key={typeof b === "string" ? b : b.key}>{b}</Breadcrumb.Item>
                ))}
              </>
            )}
          </Breadcrumb>
          <div style={{ backgroundColor: "#FFF", padding: 24, minHeight: "70vh" }}>{children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}>&copy; 2024 | Major Exchanges</Footer>
      </Layout>
    </>
  )
}

export default MainLayout
